
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.filter-ticket {

  &__is-fired {
    margin-bottom: 26px;
  }

  &__search-user {
    margin-bottom: 24px;
  }

  &__search-service {
    margin-bottom: 24px;
  }

  &__dates {
    display: flex;
    margin-bottom: 26px;

    @include for-size(phone-portrait-down) {
      flex-direction: column;
    }
  }

  &__date {
    &:first-child {
      margin-right: 24px;

      @include for-size(phone-portrait-down) {
        margin-right: 0;
        margin-bottom: 16px;
      }
    }
  }

  &__user-contact {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  &__user-name {
    margin-right: 16px;
    font-size: 14px;
    line-height: 20px;
    color: $color-black;
    transition: color $base-animation-time;
  }

  &__user-email {
    font-size: 12px;
    line-height: 16px;
    color: $color-black-op-50;
    margin-right: 16px;
  }

  &__user-appointment {
    font-size: 12px;
    line-height: 16px;
    color: $color-black-op-40;
  }

  &__user-fired {
    font-size: 12px;
    line-height: 16px;
    color: $color-red;
  }
}
